import '@brightspace-ui/core/components/switch/switch.js';
import '@brightspace-ui/core/components/button/button-icon';
import '@brightspace-ui/core/components/status-indicator/status-indicator';
import '@brightspace-ui/core/components/empty-state/empty-state-illustrated.js';
import '@brightspace-ui/core/components/icons/icon.js';
import '@brightspace-ui/core/components/table/table-controls.js';
import '@brightspace-ui/core/components/table/table-col-sort-button.js';
import '@brightspace-ui/core/components/tooltip/tooltip.js';
import '@brightspace-ui/core/components/tag-list/tag-list.js';
import '@brightspace-ui/core/components/tag-list/tag-list-item.js';
import '../../../shared/components/general/app-link/app-link.js';
import { navigator as nav } from 'lit-element-router';
import { repeat } from 'lit/directives/repeat.js';

import { bodySmallStyles } from '@brightspace-ui/core/components/typography/styles.js';
import { tableStyles } from '@brightspace-ui/core/components/table/table-wrapper.js';

import { css, html, LitElement, nothing } from 'lit';

import { LocalizeNova } from '../../../shared/mixins/localize-nova/localize-nova.js';

class PermissionsTable extends LocalizeNova(nav(LitElement)) {
  static get properties() {
    return {
      admins: { type: Array, attribute: false },
      allRoles: { type: Array, attribute: false },
      _sortDesc: { type: Object },
      _sortFacet: { type: String },
    };
  }

  static get styles() {
    return [
      bodySmallStyles,
      tableStyles,
      css`
      td.roles-column {
        width: 30%;
      }

      td.emails-column {
        overflow-wrap: break-word;
        word-break: break-word;
      }
`];
  }

  constructor() {
    super();
    this.admins = [];
    this.allRoles = [];
    this.tenantId = '';
    this._sortDesc = false;
    this._deleteDialogOpen = false;
  }

  connectedCallback() {
    super.connectedCallback();
  }

  render() {
    const sortedAdmins = this.admins.sort((a, b) => {
      if (this._sortFacet === 'name') {
        return this._sortDesc ? (b.firstName || '').localeCompare(a.firstName || '') : (a.firstName || '').localeCompare(b.firstName || '');
      } else if (this._sortFacet === 'email') {
        return this._sortDesc ? (b.email || '').localeCompare(a.email || '') : (a.email || '').localeCompare(b.email || '');
      } else if (this._sortFacet === 'title') {
        return this._sortDesc ? (b.title || '').localeCompare(a.title || '') : (a.title || '').localeCompare(b.title || '');
      }
      return 0;
    });

    const emptyState = sortedAdmins.length === 0 ?
      html`
        <tr>
          <td colspan="6" class="empty-state-container">
            <div class="empty-state">
              <d2l-empty-state-illustrated illustration-name="desert-road" title-text="${this.localize('edit-permissions.userPermissions.error.noUsers')}" description="${this.localize('edit-permissions.userPermissions.error.description')}">
              </d2l-empty-state-illustrated>
            </div>
          </td>
        </tr>` : nothing;

    return html`
      <d2l-table-wrapper>
        <table class="d2l-table">
          <thead>
          <tr class="table-header">
            <th><d2l-table-col-sort-button id="name" ?nosort="${this._sortFacet !== 'name'}" ?desc="${this._sortDesc}" @click="${this._handleSort}">${this.localize('edit-permissions.permissions-table.header.name')}</d2l-table-col-sort-button></th>
            <th><d2l-table-col-sort-button id="email" ?nosort=${this._sortFacet !== 'email'} ?desc="${this._sortDesc}" @click="${this._handleSort}">${this.localize('edit-permissions.permissions-table.header.email')}</d2l-table-col-sort-button></th>
            <th><d2l-table-col-sort-button id="title" ?nosort=${this._sortFacet !== 'title'} ?desc="${this._sortDesc}" @click="${this._handleSort}">${this.localize('edit-permissions.permissions-table.header.jobTitle')}</d2l-table-col-sort-button></th>
            <th>${this.localize('edit-permissions.permissions-table.header.permissions')}</th>
            <th>${this.localize('edit-permissions.permissions-table.header.status')}</th>
            <th>${this.localize('edit-permissions.permissions-table.header.action')}</th>
          </tr>
          </thead>
          <tbody>
            ${emptyState}
            ${repeat(this.admins, admin => admin.guid, admin => html`
              <tr>
                <td>${admin?.firstName} ${admin?.lastName}</td>
                <td class="emails-column">${admin?.email}</td>
                <td>${admin?.title}</td>
                <td class="roles-column">
                  ${this._renderRoleList(admin?.roles)}
                </td>
                <td>
                  <d2l-switch text-position="hidden" text="${this.localize('manage-user-permissions.userActiveSwitchLabel')}" @change="${this._changeActiveState(admin)}" ?on="${admin?.active}"></d2l-switch>
                </td>
                <td class="action">
                  <app-link class="edit-user-button" href="/tenants/${this.tenantId}/permissions/${admin.guid}">
                    <d2l-button-icon text="${this.localize('edit-permissions.permissions-table.header.editAdmin')}" icon="tier1:edit" ></d2l-button-icon>
                  </app-link>
                </td>
            </tr>
            `)}
          </tbody>
        </table>
      </d2l-table-wrapper>
    `;
  }

  _renderRoleList(roles) {
    if (!roles?.length) {
      return html `<d2l-icon icon="tier1:alert"></d2l-icon> <span class="d2l-body-small">${this.localize('edit-permissions.permissions-table.header.noRoles')}</span>`;
    } else {
      return html `
        <d2l-tag-list>
          ${repeat(roles, role => role.roleId, role => { const roleName = this.allRoles.find(r => r.roleId === role.roleId)?.roleName; return html`
            <d2l-tag-list-item text="${roleName}"></d2l-tag-list-item>
          `;})}
        </d2l-tag-list>
      `;
    }
  }

  _changeActiveState(admin) {
    return e => {
      e.target.on = admin.active;
      this.dispatchEvent(new CustomEvent('nova-admin-state-change', {
        detail: { admin },
        bubbles: true,
        composed: true,
      }));
    };
  }

  _handleSort(e) {
    const desc = e.target.hasAttribute('desc');
    this._sortFacet = e.target.id;
    this._sortDesc = !desc;
  }

}

window.customElements.define('permissions-table', PermissionsTable);
