import { dedupeMixin } from '@open-wc/dedupe-mixin';
import { navigator as nav } from 'lit-element-router';
import { nothing } from 'lit';

import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import { FULL_PERMISSIONS } from '../../../../shared/permissions.js';

export const PERMISSION_FAILURE_TYPES = {
  REDIRECT: 'redirect',
  HIDE: 'hide',
};

export const NovaPermissionMixin = dedupeMixin(superclass => class extends RequesterMixin(nav(superclass)) {

  static get properties() {
    return {
      requiredPermissions: { type: Object }, // Either a string or an array of strings
      allowed: { type: Boolean, reflect: true },
      _userPermissions: { type: Array },
      handlePermissionFailure: { type: String }, // One of the permission failure types
    };
  }

  constructor() {
    super();
    this.allowed = true;
    this._requiredPermissions = [];
    this.handlePermissionFailure = PERMISSION_FAILURE_TYPES.HIDE; // Default behavior is to hide
  }

  // Instead of using a setter, leverage willUpdate to normalize the permissions
  willUpdate(changedProperties) {
    if (changedProperties.has('requiredPermissions')) {
      this._normalizePermissions();
    }
  }

  _normalizePermissions() {
    const value = this.requiredPermissions;
    if (typeof value === 'string') {
      this._requiredPermissions = [value];
    } else if (Array.isArray(value)) {
      this._requiredPermissions = value;
    } else {
      console.warn('Invalid requiredPermissions value, must be a string or an array');
      this._requiredPermissions = [];
    }
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
  }

  get _userPermissions() {
    const adminRolesEnabled = this.session.tenant.hasTag('adminRoles');
    if (!adminRolesEnabled) return Object.keys(FULL_PERMISSIONS);

    return this.session.user.roles.reduce((acc, role) => {
      return [...new Set([...acc, ...role.rolePermissions])];
    }, []);
  }

  _hasPermission() {
    if (!this._requiredPermissions || this._requiredPermissions.length === 0) {
      return true;
    }

    return this._requiredPermissions.every(permissionGroup => {
      if (Array.isArray(permissionGroup)) {
        // "OR" logic: at least one permission in the group must be present
        return permissionGroup.some(permission =>
          this._userPermissions.includes(permission)
        );
      } else {
        // Single permission: must be present
        return this._userPermissions.includes(permissionGroup);
      }
    });
  }

  updated(changedProperties) {
    if (changedProperties.has('_userPermissions') || changedProperties.has('requiredPermissions')) {
      this.allowed = this._hasPermission();
      if (!this.allowed) {
        this._handlePermissionFailure();
      }
      // check that requiredPermissions are all valid(and can be found in the FULL_PERMISSIONS object)
      // const checkPerms = this.requiredPermissions || [];
      // TODO: Fix this check
      // checkPerms.forEach(permission => {
      //   if (!FULL_PERMISSIONS[permission]) {
      //     console.error(`Invalid permission: ${permission}`);
      //   }
      // });
    }
  }

  _handlePermissionFailure() {
    if (this.handlePermissionFailure === PERMISSION_FAILURE_TYPES.REDIRECT) {
      if (!this._hasPermission()) {
        this._redirectTo403();
      }
    }
    // If the setting is 'hide', no action is needed because the render method will handle it
  }

  _redirectTo403() {
    this.navigateWithoutHistory('/403');
  }

  notAllowedRender() {
    return nothing;
  }

  allowedRender() {
    return nothing;
  }

  render() {
    if (!this._hasPermission() && this.handlePermissionFailure === PERMISSION_FAILURE_TYPES.HIDE) {
      return this.notAllowedRender();
    }
    return this.allowedRender();
  }
});
