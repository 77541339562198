import '@brightspace-ui/core/components/button/button.js';
import '../../../../components/permissions-table/permissions-table.js';
import '@brightspace-ui/core/components/tabs/tabs.js';
import '@brightspace-ui/core/components/tabs/tab-panel.js';

import { css, html, LitElement } from 'lit';

import { SkeletonMixin } from '@brightspace-ui/core/components/skeleton/skeleton-mixin.js';

import { RequesterMixin } from '@brightspace-ui/core/mixins/provider-mixin.js';

import { LocalizeNova } from '../../../../../shared/mixins/localize-nova/localize-nova.js';

class EditPermissions extends LocalizeNova(RequesterMixin(SkeletonMixin(LitElement))) {
  static get properties() {
    return {
      admins: { type: Array, attribute: false },
      allRoles: { type: Array, attribute: false },
      tenantId: { type: String, attribute: false },
      _deleteDialogOpen: { type: Boolean, attribute: false },
      _selectedAdmin: { type: Object, attribute: false },
    };
  }

  static get styles() {
    return [
      super.styles,
      css`
      /* Add styles here */
`];
  }

  constructor() {
    super();
    this.admins = [];
    this.allRoles = [];
    this.tenantId = '';
    this._selectedAdmin = null;
    this._deleteDialogOpen = false;
  }

  connectedCallback() {
    super.connectedCallback();
    this.session = this.requestInstance('d2l-nova-session');
    this.client = this.requestInstance('d2l-nova-client');
  }

  async firstUpdated() {
    this.skeleton = true;
    this.allRoles = await this.client.getAllRoles();
    this.tenantId = this.session._tenant.id;
    this.admins = await this.client.getAllUsersByTenantId(this.tenantId, { includeRoles: true });
    this.skeleton = false;
  }

  render() {
    const activeAdmins = this.admins.filter(admin => admin.active);
    const inactiveAdmins = this.admins.filter(admin => !admin.active);
    return html`
      <h2>${this.localize('edit-permissions.userPermissions')}</h2>
      <p>${this.localize('edit-permissions.userPermissions.description')}</p>
      <d2l-tabs>
        <d2l-tab-panel selected text="${this.localize('edit-permissions.userPermissions.activeTab')}">
          <permissions-table class="d2l-skeletize" @nova-admin-state-change="${this._activeStateChanged}" .allRoles=${this.allRoles} .admins=${activeAdmins} .tenantId="${this.tenantId}"></permissions-table>
        </d2l-tab-panel>
        <d2l-tab-panel text="${this.localize('edit-permissions.userPermissions.inactiveTab')}">
          <permissions-table @nova-admin-state-change="${this._activeStateChanged}" .allRoles=${this.allRoles} .admins=${inactiveAdmins} .tenantId="${this.tenantId}"></permissions-table>
        </d2l-tab-panel>
      </d2l-tabs>
      ${this._confirmationDialogTemplate}
    `; }

  async _activeStateChanged(e) {
    const { admin } = e.detail;
    this._selectedAdmin = admin;
    this._deleteDialogOpen = true;
  }

  get _confirmationDialogTemplate() {
    return html`
      <confirmation-dialog
        ?opened=${this._deleteDialogOpen}
        type="toggleUserActiveStatusConfirmation"
        .data="${this._selectedAdmin}"
        @d2l-dialog-close=${this._dialogClose}>
      </confirmation-dialog>`;
  }

  async _dialogClose(e) {
    // TODO: actually update the user active status and roles here
    const { action } = e.detail;
    this._deleteDialogOpen = false;

    if (action === 'toggleActiveStatus') {
      this._selectedAdmin.active = !this._selectedAdmin.active;
      try {
        await this.client.toggleUserActiveStatus(this._selectedAdmin);
        const message = this._selectedAdmin?.active ? this.localize('edit-permissions.permissions-table.activateUserRolesConfirmation.toast') : this.localize('edit-permissions.permissions-table.deactivateUserRolesConfirmation.toast');
        this.session.toast({ type: 'default', message });
        this.admins = await this.client.getAllUsersByTenantId(this.tenantId, { includeRoles: true });
      } catch (error) {
        const message = this._selectedAdmin?.active ? this.localize('edit-permissions.permissions-table.activateUserRolesConfirmationFail.toast') : this.localize('edit-permissions.permissions-table.deactivateUserRolesConfirmationFail.toast');
        this.session.toast({ type: 'critical', message });

      }
    }
  }

}

window.customElements.define('edit-permissions', EditPermissions);

